///////////////////////////////////////////// bootstrap ////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans:wght@300;400&display=swap');

///////////////////mysa theme color //////////////////////////

$mysa-black: #000000;
$mysa-blue: #19AFE6;
$mysa-teal: #2DBDB6;
$mysa-yellow: #fAC90E;
$mysa-megenta: #E7008C;
$mysa-grey: #F2F2F2;
$mysa-white: #FFFFFF;
$mysa-red: #FF0000;
$mysa-charcoal: #5f5f5f;

body {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Open Sans', sans-serif;
  color: $mysa-black !important;
  background: $mysa-white !important;
}

////////////////// tooltip color ////////////////////////////
$tooltip-background : $mysa-megenta;
$tooltip-border : $mysa-megenta;
$tooltip-font : $mysa-white;

////////////////// table tooltip color ////////////////////////////
$tooltip-table-background : $mysa-black;
$tooltip-table-border : $mysa-black;
$tooltip-table-font : $mysa-white;

///////////////// routing button color ///////////////////////////
$routing-button-background : $mysa-yellow;
$routing-button-border : $mysa-yellow;
$routing-button-font : $mysa-black;

///////////////////// tab color ////////////////////////////////
$tab-background : $mysa-yellow;
$tab-font : $mysa-black;
$tab-right-border : $mysa-black;

///////////////// tooltip ////////////////////////
#tooltip-right>.tooltip-inner {
  background-color: $tooltip-background;
  color: $tooltip-font;
  border: 2px solid $tooltip-border;
  font-weight: bold;
  font-size: 14px;
  border-radius: 10px;
  padding: 15px 25px;
  max-width: 240px;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $tooltip-border !important;
}

////////////////////// tooltip table style /////////////////
#tooltip-table-top>.tooltip-inner {
  background-color: $tooltip-table-background !important;
  color: $tooltip-table-font !important;
  border: 2px solid $tooltip-table-border !important;
  font-weight: bold !important;
  font-size: 14px !important;
  border-radius: 10px !important;
  padding: 15px 25px !important;
  max-width: 240px !important;
}

.ant-tooltip-inner {
  background-color: $tooltip-table-background !important;
  color: $tooltip-table-font !important;
  border: 2px solid $tooltip-table-border !important;
  font-weight: bold !important;
  font-size: 14px !important;
  border-radius: 10px !important;
  padding: 15px 25px !important;
  max-width: 240px !important;
}

////////////////////// navbar //////////////////////////////
.navbar-inner {
  box-shadow: 0px 4px 8px #88888870;
}

///////////////////// routing button //////////////////////
#routing-button {
  // background: $routing-button-background  !important;
  // color: $routing-button-font  !important;
  // border-color: $routing-button-background  !important;
}

#routing-button-blue {
  background: $mysa-blue ;
  color: $routing-button-font ;
  border-color: $mysa-blue ;
}

#reset-button {
  background: $mysa-megenta !important;
  color: $mysa-white !important;
  border-color: $mysa-megenta !important;
}

///////////////////// approve button //////////////////////
#approve-button {
  // background: $mysa-teal  !important;
  // color: $mysa-white  !important;
  // border-color: $mysa-teal  !important;
}

///////////////////// edit button //////////////////////
#edit-button,
.edit_btn {
  // background: $mysa-yellow  !important;
  color: $mysa-black !important;
  // border-color: $mysa-yellow  !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  // margin-right: 30px !important;
  box-shadow: 0 3px 10px #FAC90E !important;

  &:disabled {
    background: $mysa-grey !important;
    color: lighten($mysa-black, 50%) !important;
    border-color: $mysa-grey !important;
  }
}

#megenta-button,
.edit_btn {
  // background: $mysa-yellow  !important;
  color: $mysa-black !important;
  // border-color: $mysa-yellow  !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  // margin-right: 30px !important;
  box-shadow: 0 3px 10px $mysa-megenta !important;

  &:disabled {
    background: $mysa-grey !important;
    color: lighten($mysa-black, 50%) !important;
    border-color: $mysa-grey !important;
  }
}

#blue-button,
.edit_btn {
  // background: $mysa-yellow  !important;
  color: $mysa-black !important;
  // border-color: $mysa-yellow  !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  // margin-right: 30px !important;
  box-shadow: 0 3px 10px $mysa-blue !important;

  &:disabled {
    background: $mysa-grey !important;
    color: lighten($mysa-black, 50%) !important;
    border-color: $mysa-grey !important;
  }
}

#back-button {
  background: $mysa-black !important;
  // color: $mysa-white  !important;
  border-color: $mysa-black !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: none !important;
  color: $mysa-white !important;
}

#login-button {
  background: $mysa-yellow !important;
  // color: $mysa-white  !important;
  border-color: $mysa-yellow !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: none !important;

  &:disabled {
    background: $mysa-grey !important;
    color: lighten($mysa-black, 50%) !important;
    border-color: $mysa-grey !important;
  }
}

///////////////////// remove button //////////////////////
#remove-button {
  background: $mysa-yellow !important;
  color: $mysa-black !important;
  border-color: $mysa-yellow !important;
  margin: 0 25px;
  font-weight: bold;
  border-radius: 44px;
}

///////////////////// open button //////////////////////
#open-button {
  background: white !important;
  color: $mysa-teal !important;
  border-color: white !important;
  margin: 0 25px;
  font-weight: bold;
  border-radius: 44px;
}


#create-button {
  // background: $mysa-teal !important;
  // color: $mysa-white !important;
  // border-color: $mysa-teal !important;
  // border-radius: 44px !important;
  background: transparent !important;
  height: 42px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 10px #2dbdb6 !important;
  border-color: transparent !important;
  color: #000 !important;
  padding-left: 30px;
  padding-right: 30px;
}



//////////////// icon sizes /////////////////////
.iconSizes {
  width: 30px;
  height: 25.83px;
  cursor: pointer;
}

.iconSizes5 {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.iconSize {
  width: 30px;
  height: 25.83px;
  // cursor: pointer;
}

.iconSizes2 {
  width: 25%;
  height: 50%;
  cursor: pointer;
}

.iconSizes3 {
  width: 10%;
  height: 50%;
  cursor: pointer;
}

.iconSizes4 {
  width: 15.25px;
  height: 20.2px;
  cursor: pointer;
  // margin-right: -2px;
}

//////////////////////// table header ////////////////////////
.rdt_TableHeadRow {
  font-weight: bold;
  border-radius: 11px;
  margin-bottom: 15px;
}




///////////////////// resend button //////////////////////
#resend-button {
  background: $mysa-megenta;
  color: $mysa-white;
  border-color: $mysa-megenta;
}

////////////////////////breadcrump style ////////////////////////
.breadcrumb-item.active {
  color: $mysa-black;
  font-weight: bold;
}

////////////////////////// tab style ///////////////////////////
.nav-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav-pills {

  .nav-link {
    color: $tab-font !important;
    font-weight: bold;
  }

  .nav-link.active,
  .show>.nav-link {
    color: $tab-font !important;
    background-color: $tab-background !important;
    font-weight: bold;
    // border-right: 5px solid $tab-right-border  !important;
  }
}

//////////////tab content /////////////////
.content-title {
  font-size: 18px;
  font-weight: bold;
}

.jumbotron {
  padding: 3rem 5rem;
  margin-bottom: 2rem;
  margin-top: 20px;
  background-color: $mysa-grey;
  border-radius: 3.3rem;
}


#request-availability {
  overflow: hidden;
  background-color: $mysa-grey;
  border-radius: 28px;
}

#about-property {
  padding: 3rem 5rem !important;
  margin-bottom: 2rem !important;
  // margin-top: 20px !important;
  // background-color: $mysa-grey;
  border-radius: 44px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  // width:1084px;
  // height:373px;
}

#about-brand {
  padding: 3rem 5rem !important;
  margin-bottom: 2rem !important;
  // margin-top: 20px !important;
  background-color: $mysa-grey;
  border-radius: 44px;
  // border: 1px solid rgba(0, 0, 0, 0.4);
  // width:1084px;
  // height:373px;
}

.property-card {
  // padding: 1rem 2rem;
  margin-bottom: 2rem;
  margin-top: 20px;
  background-color: $mysa-grey;
  border-radius: 44px;
  // border: 1px solid rgba(0, 0, 0, 0.4);
  // width:1084px;
  // height:373px;
}


#audit {
  padding: 3rem 5rem;
  margin-bottom: 2rem;
  margin-top: 20px;
  background-color: #C4C4C4;
  // border-radius: 44px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  // width:1084px;
  // height:373px;
  // position: absolute;
  // width: 1084px;
  height: 350px;
}




#booking-engine {
  border: none;
  background-color: $mysa-grey;
  // height: px;
}

//////////// form //////////////////
.form-label {
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: bold;
}

.lead {
  font-size: 18px !important;
}

.requiredMark {
  color: $mysa-megenta !important;
}

.formSpace {
  margin-top: 25px !important;
}

.formSpaceLogin {
  margin-top: 25px !important;
}


.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

////////////////// tags input ///////////////

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

////////////////////////// model ///////////////////////////
.modal-content {
  border-radius: 2.3rem !important;
}


// .modal {
//   position: fixed !important;
//   top: 250px !important;
//   left: 571px !important;
//   z-index: 1055;
//   display: none;
//   width: 100%;
//   height: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
//   outline: 0;
// }

////////////////////////// breadcrumb ///////////////////////

.breadcrumb-item {
  font-size: 16px !important;
  font-family: Montserrat;

  +.breadcrumb-item {
    &::before {
      color: $mysa-blue !important;
      margin-top: 3px;
    }
  }

  &.active {
    // color: $mysa-black  !important;
    color: $mysa-blue !important;
    font-size: 16px;
    font-weight: bold;
  }
}

.breadcrumb-item:not(:last-child) {
  font-weight: 500 !important;
}

.breadcrumb-item a {
  color: $mysa-blue !important;
}

a {
  text-decoration: none !important;
  color: $mysa-black !important;

  &:hover {
    text-decoration: none !important;
  }
}

////////////////////switch ///////////////
#custom-switch {
  // background-color: $mysa-black !important;
  border-color: $mysa-black !important;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: black !important;
  border-color: black !important;
  cursor: default !important;
}

.toggle {
  // background: #FFFFFF;
  background: #FBC91D !important
}

#dropdown-basic {
  border-radius: 14px;
  background: #FBC91D;
  color: #000000;
  border: none !important;
  width: 209px !important;
  text-align: left !important;

  &:focus {
    box-shadow: none
  }
}


#dropdown-brandRfp {
  border-radius: 14px;
  position: relative;
  background: #fff !important;
  color: #808080 !important;
  border: none !important;
  width: 209px !important;
  text-align: left !important;
  box-shadow: 0 3px 10px #22aee2 !important;

  &:focus {
    box-shadow: none
  }
}

#dropdown-brandRfp:after {
  position: absolute;
  right: 10px;
  top: 10%;
  border: 0;
  height: 80%;
  padding: 4px;
  background-image: url(../images/icons/down.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: "";
  width: 20px;
}

#dropdown-brandRfp:before {
  position: absolute;
  border-left: 1px solid #80808059 !important;
  content: "";
  right: 38px;
  top: 10%;
  border: 0;
  height: 80%;
}

.dropdown-menu {
  border: 1px solid #FFFFFF !important;
  border-radius: 0% !important;
  width: 209px !important;
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.toggle-selected {
  border-top-right-radius: 14px !important;
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #FFFFFF !important;
}


.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #FBC91D !important;
}

.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: rgba(251, 201, 29, 0.2) !important;
}

.message {
  color: $mysa-teal
}

//////////// pdf /////////////
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 210% !important;
  height: 100% !important;
}


///////////////////// share button //////////////////////
#share-button {
  background: $mysa-white !important;
  color: $mysa-black !important;
  border-color: $mysa-yellow !important;
}

///////////////////// share button //////////////////////
#calendar-button {
  background: $mysa-white !important;
  color: $mysa-black !important;
  border: 1px solid #999999 !important;
}

#show-commute {
  border: 1px solid $mysa-yellow;
  border-color: $mysa-yellow !important;
  position: relative;
  width: 90.73px;
  margin: auto;
}

#selfCheckIn-button {
  background: #FCEEB0 !important;
  color: $mysa-black !important;
  border-color: #FCEEB0 !important;
}

// create update wrapper
.create-update-wrapper {
  // width: 80%;
  padding: 3rem 3rem;
  // margin-bottom: 2rem;
  // margin-top: 20px;
  margin: 68px auto 20px;
  background-color: $mysa-grey;
  border-radius: 44px;
}

.form-error {
  color: #c92432;

}

// for the booking engine
#booking-dropdown {
  .dropdown-toggle::after {
    display: none !important;
  }

  &-toggle {
    width: 321px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 11px;
    color: #000000;
    text-align: left;
    padding-left: 20px;
  }

  &-menu {
    width: 321px !important;

    border: 1px solid #999999 !important;

  }
}

.booking-form {
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}


#confirmation-checkIn {
  padding: 0px 0px;
  margin-bottom: 2rem;
  margin-top: 10px;
  // background-color: $mysa-grey;
  border-radius: 44px;
  border: 2px solid $mysa-yellow !important;
}


/*This is modifying the btn-primary colors but you could create your own .btn-something class as well*/
.btn-audit {
  color: $mysa-black;
  background-color: transparent;
  border: 0 !important;
  border-bottom: 4px solid #0000 !important;
  /*set the color you want here*/
}

.btn-audit:hover,
.btn-audit:focus,
.btn-audit:active,
.btn-audit.active,
.open>.dropdown-toggle.btn-audit {
  color: $mysa-black;
  border: 0 !important;
  border-bottom-color: $mysa-yellow !important;
  border-radius: 0 !important;
  /*set the color you want here*/
}

.custom-form-control {
  display: block;
  width: 80%;
  padding: 0.1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.1;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 4%;
  margin-top: 19px;
}

// New Scss Start
$mysa-blue-dark: #22aee2;

#selfCheckIn-button {
  &.checkin-reception {
    &.bookingCheckinProcess {
      background: transparent !important;
      outline: none;
    }
  }
}

button#routing-button {
  &.bookingViewProperty {
    background: $mysa-teal !important;
    color: #fff !important;
    border-color: $mysa-teal !important;
  }
}

button#resend-button {
  &.bookingRequestAvailability {
    background: $mysa-blue-dark !important;
    color: #fff;
    border-color: $mysa-blue-dark !important;
    width: 100% !important;
    padding: 0 12px !important;
  }
}

button#share-button {
  &.bookingShareProperty {
    width: 100% !important;
  }
}

button#approve-button {
  &.bookingBookNow {
    background: #fAC90E !important;
    border-color: #fAC90E !important;
    width: 100% !important;
  }

}


button#approve-button {

  &.import {
    background: #fAC90E !important;
    border-color: #fAC90E !important;
    width: 50% !important;
    box-shadow: #fAC90E !important;
  }
}

.sharePopertyBox {
  button#approve-button {
    background: #fac90e !important;
    border-color: #fac90e !important;
    display: table;
  }
}

#about-brand.aboutFisher,
#about-property.aboutProperty,
.mysaAudits {
  padding: 2.5rem !important;
  margin-bottom: 2rem !important;
}

.mysaAudits {
  border-radius: 44px;
  border: 1px solid rgba(0, 0, 0, .4);
}

// New Css 
div.sharePopertyBox {
  max-width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
}

.bookingListBtnGroup {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sharePopertyBox {
  &.sharePopertyBoxMinimize {
    padding-bottom: 0 !important;
    width: 300px;
    top: auto;
    left: auto;
    transform: translate(0px, 0px);
    bottom: 0;
    right: 0;

    &.requestBoxMinimize {
      right: 310px;
    }

    .sharePropertyHeader {
      margin-bottom: -4px !important;
    }

    .minimizeModal {
      display: none;
    }
  }
}

.essentialInfo {
  ul {
    li {
      .bedroomEssInfo {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

//wysiwyg 
.demo-editor {
  background: #FFFFFF !important;
}