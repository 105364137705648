@import "~antd/dist/antd.css";
@import "./common.scss";
@import "./audit.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');

// ///////////////////////////// font added ////////////////////////////
// body {
//   font-family: 'Montserrat', sans-serif;
// }
.fz-32 {
  font-size: 32px;
  font-weight: 700;
  font-family: 'Montserrat';
}

.fz-24 {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat';
}

.flex-bx {
  display: flex;
  gap: 20px;
}

.flex-bx .fz-32 {
  font-size: 22px !important;
}

.fw-rg {
  font-weight: 400 !important;
}

.fw-sb {
  font-weight: 500 !important;
}

///////////////////////////////////////////////////// 
.mysa-login {
  background-image: url(../images/backgroundNew.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .login-btn {
    background: $mysa-blue;
    border-color: $mysa-blue;
  }
}

/////////////////////side menu ///////////////////////////////////////////////////
.mysa-side-menu {
  .side-menu {
    box-shadow: 0px 10px 15px 7px #ccc;
  }

  .expand {
    background: #f0f0f0;
    transform: translate(80px, 8px);
    width: 250px;
    box-shadow: 10px 0px 10px -5px #ccc;
    opacity: 1;
    padding: 15px;
    z-index: 15;
  }

  .collapse {
    background: #f0f0f0;
    transform: translate(-250px, 8px);
    width: 250px;
    box-shadow: 10px 0px 10px -5px #ccc;
    opacity: 0;
    padding: 15px;
    z-index: 15;
  }

  .text-menu {
    a {
      color: black;
    }

    .selected,
    .logout {
      color: $mysa-blue;
      cursor: pointer;
    }
  }

  .divider {
    background: #ccc;
    height: 1px;
    margin-top: -6px;
    width: 125%;
    transform: translateX(-20px);
  }

  .divider2 {
    background: #ccc;
    height: 1px;
    width: 115%;
    transform: translateX(-15px);
    margin-top: -1px;
  }

  .menu-icon {
    border: 1px solid gray;
    border-radius: 50px;
    padding: 15px;
  }
}

// .mysa-sub-menu {
//   position: fixed;
//   width: 200px;
//   height: 100vh;
//   top: 88px;
//   left: 80px;
//   background: #f0f0f0;
//   a {
//     color: black;
//   }
//   .selected,
//   .logout {
//     color: $mysa-blue;
//     cursor: pointer;
//   }
// }

.mysa-sub-menu {
  margin-left: -30px;

  .ant-tabs-nav {

    // position: fixed;
    // top: 88px;
    // left: 80px;
    // width: 201px;
    // height: 100vh;
    // background: #f0f0f0;
    // .ant-tabs-nav-list {
    //   // margin-top: 30px;
    // }
    .ant-tabs-tab-active {
      background: aliceblue;
    }
  }

  // .ant-tabs-content-holder {
  //   // background: aquamarine;
  //   // margin-left: 160px;
  // }
}

///////////////////////////////// brand-list ////////////////////////////////////////////
// rfp process toggle style
.toggle {
  background: #FFFFFF;
}


/**********/
.Fancy_wrapper {
  position: relative;
}

.Fancy_wrapper .fancyBtn {
  left: 50%;
  transform: translateX(-50%);
}

/*Login Form*/
.rdt_Table {
  padding: 6px;
}

.rdt_Table .rdt_TableHeadRow {
  background: #fff;
  box-shadow: 0px 5px 8px #00000024 !important;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  background: #fff;
  color: #000;
  border-right: 2px solid #000;
}

.reports_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  width: 110px !important;
  background: #324E5D;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-right: 2px solid #cd97b6;
  padding-left: 15px;
  padding-right: 15px;
}

.reports_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(1),
.reports_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  min-width: 120px !important;
}

.reports_table .rdt_TableRow .rdt_TableCell:nth-child(1) div,
.reports_table .rdt_TableRow .rdt_TableCell:nth-child(2) div {
  white-space: unset;
  overflow: unset;
  text-overflow: ellipsis;
}

.contractedProperty .reports_table .rdt_Table .rdt_TableHeadRow {
  padding-left: 0;
  padding-right: 0;
}



.reports_table .rdt_Table .rdt_TableRow .rdt_TableCell {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.reports_table .rdt_TableRow:nth-child(even) .rdt_TableCell {
  background: #cceff9;
}

.reports_table .rdt_Table .rdt_TableHeadRow {
  background: #324E5D;
  border-radius: 0;
  margin: 0;
  min-height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}

// .reports_table .rdt_TableCol_Sortable span {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }



.rdt_Table .rdt_TableHeadRow .rdt_TableCol:last-child {
  border-right: 0;
}

.rdt_TableCell div {
  font-size: 16px;
}

#react-aria1238529438-7-tabpane-brandUsers .rdt_TableHeadRow .rdt_TableCol:first-child,
#react-aria1238529438-7-tabpane-brandUsers .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
#react-aria1238529438-7-tabpane-brandUsers .rdt_TableRow .rdt_TableCell:nth-child(2),
#react-aria1238529438-7-tabpane-brandUsers .rdt_TableRow .rdt_TableCell:first-child {
  width: 15% !important;
  min-width: 15%;
}

#react-aria1238529438-7-tabpane-brandUsers .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
#react-aria1238529438-7-tabpane-brandUsers .rdt_TableRow .rdt_TableCell:nth-child(3) {
  width: 22% !important;
  min-width: 22%;
}

/* Select btn */
.select_btn {
  box-shadow: 0px 3px 10px #fac90e;
  border-radius: 20px;
}

.select_btn span+div {
  height: 42px;
  background-color: transparent !important;
  border-radius: 20px !important;
  // border:0;
}

.select_btn2 {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.select_btn2 span+div {
  border-radius: 12px !important;
}

.breadcrumb_fix {
  z-index: 99;
  display: flex;
  align-items: center;
  line-height: 42px;
}

.breadcrumb_fix ol {
  margin-bottom: 0;
}

.breadcrumb_main .btn {
  height: 42px !important;
  font-size: 16px;
}

.ant-breadcrumb .ant-breadcrumb-link {
  font-size: 18px;
}

.ant-breadcrumb span:last-child .ant-breadcrumb-link {
  font-weight: 700;
}

/******/
.btn_design {
  border-radius: 20px !important;
  height: 42px !important;
}

.btn_design,
.btn,
#routing-button-blue,
#routing-button,
.fancyBtn {
  background: transparent !important;
  height: 38px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  box-shadow: 0 3px 10px #2DBDB6 !important;
  border-color: transparent !important;
  color: #000 !important;
}

.btn_design span {
  color: #000;
}

.btn_wrapper .col-3:first-child .select_btn {
  border-radius: 12px !important;
  box-shadow: none;
}

#approve-button+#edit-button {
  box-shadow: 0 3px 10px #fac90e !important;
}

.btn_wrapper .col-3:first-child .select_btn span+div {
  border-radius: 12px !important;
}

.survey-section button:hover {
  color: #1db2ec !important;
}

.Fancy_wrapper {
  min-height: 360px;
}

.jumbotron .btn {
  margin-top: 25px;
}

// Waiting sec
.waiting-sec {
  box-shadow: 0 0 10px #00000033;
  border-radius: 30px;
  padding: 30px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto 20px;
}

.logo-wrp {
  max-width: 200px;
  margin: 20px 0;
}

.waiting-inner h2 {
  font-size: 28px;
  font-weight: 700;
}

.waiting-inner h6 {
  font-size: 18px;
}

.after-logo {
  position: relative;
}

.after-logo:after {
  position: absolute;
  content: "";
  width: 30%;
  height: 72%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../images/logoNew.png');
  right: 0;
  top: 50%;
  filter: grayscale(1);
  opacity: 7%;
  transform: translateY(-50%);
}

.color-blue {
  color: #22aee2 !important;
}

.color-yellow {
  color: #fbca21 !important;
}

.secondry-menu {
  background: #fff !important;
}

.secondry-menu .nav-link {
  box-shadow: 0 0 16px #0000001f;
  border-radius: 22px !important;
  border: 0 !important;
  margin-left: 10px;
}

.bookingListContGroup #selfCheckIn-button {
  box-shadow: none !important;
}

.bookingListContGroup div.text-left+div.text-left {
  min-height: 42px;
}

// Table
.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  max-width: 200px;
}

.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  width: 100%;
  max-width: 360px;
}

.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
  max-width: 235px;
}

.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
  max-width: 210px;
}

.brandUser_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
  max-width: 220px;
}


.brandUser_table .rdt_TableCell:nth-child(6) {
  max-width: 220px;
}

.brandUser_table .rdt_TableCell:nth-child(5) {
  max-width: 230px;

}

// .brandUser_table .rdt_TableCell:nth-child(4) {
//   max-width: 200px;
// }
.brandUser_table .rdt_TableCell:nth-child(4) {
  max-width: 210px;
}

.brandUser_table .rdt_TableCell:nth-child(3) {
  max-width: 360px;
}

.brandUser_table .rdt_TableCell:nth-child(2) {
  max-width: 202px;
}

.brandUser_table .rdt_TableCell:nth-child(1) {
  max-width: 214px;
}

// Tab Content
.tab-content {
  position: relative;
  padding-top: 30px;
}

.tab-content nav[aria-label="breadcrumb"] {
  position: fixed;
  background: #fff;
  max-width: 80%;
  top: 81px;
  // width: 100%;
  padding: 35px 0 0;
  z-index: 999;
}


.bc_unset {
  position: unset !important;
  max-width: 100% !important;
  padding: unset !important;
}

// .bc_unset .breadcrumb-item a,
// .bc_unset .breadcrumb-item.active,
// .bc_unset .breadcrumb-item + .breadcrumb-item::before{
//   color: #000 !important;
// }
.fixed_title {
  position: fixed;
  display: flex;
  align-items: center;
  top: 83px;
  color: #22aee2;
  width: 80%;
  padding: 26px 38px 15px 0;
  background-color: #fff;
  z-index: 9;
}

.tab-pane.active .row .content-title {
  color: #22aee2;
  font-family: 'Montserrat';
  font-size: 20px;
}

// Tab pane
.tab-pane .jumbotron:first-child {
  margin-top: 65px;
}

.helpModal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.helpModal .modal-dialog {
  max-width: 800px;
}

.helpModal .modal-header {
  padding: 20px 30px;
  background: #e80f8b;
  border-radius: 20px 20px 0 0;
}

.helpModal .modal-title img {
  max-width: 150px;
  filter: drop-shadow(2px 4px 6px #0000009e);
}

.helpModal .modal-content {
  border-radius: 20px !important;
}

.hrlp_ctnt_inr {
  box-shadow: 0 0 10px #00000061;
  padding: 20px;
  border-radius: 18px;
}

/* search bar */
.fz-20 {
  font-size: 20px !important;
}

.edit_btn_wpr {
  text-align: right;
  position: fixed;
  z-index: 9999;
  top: 110px;
  right: 38px;
}

.edit_btn_wpr2 {
  text-align: center;
  // position: fixed;
  z-index: 9999;
  // top: 110px;
  right: 38px;
}

.search_bar.select_btn {
  box-shadow: 0px 3px 10px #22aee2;
  border: 0 !important;
}

.search_bar_reports.select_btn {
  box-shadow: 0px 3px 10px #e80f8b;
  border: 0 !important;
}

.search_bar.select_btn>div {
  border: 0;
}

.search_bar_reports.select_btn>div {
  border: 0;
}

.client_hdng {
  position: fixed !important;
  background: #fff !important;
  width: 100% !important;
  padding-top: 40px !important;
  top: 80px !important;
  z-index: 99 !important;
  overflow: hidden;
  max-width: 100% !important;
}

.programme-home nav {
  padding-left: 10px;
}

/*********/
.ck.ck-content ul,
.tab-pane .jumbotron:first-child {
  .ck.ck-content ul li {
    margin-top: 65px;
    list-style-type: inherit;
  }
}

.helpModal {
  .ck.ck-content ul {
    display: flex !important;
    padding-left: 40px;
    align-items: center;
  }

  justify-content: center;

  .ck.ck-content ol,
  .ck.ck-content ol li {
    list-style-type: decimal;
  }

  .ck.ck-content ol {
    padding-left: 40px;
  }

  .ck-editor__editable_inline {
    padding: 0 30px !important;
  }
}

.popover {
  z-index: 99999;
}

/*********/
.btn_align,
.btn_align_center,
.btn_align_left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.btn_align_left {
  justify-content: flex-start;
}

.btn_align_center {
  justify-content: center;
}


.btnCustom {
  box-shadow: none !important;
  border-color: #000000 !important;
  width: 25%;
}

/* Table Design */
.brndTbl .rdt_TableBody div[role="row"] div:first-child {
  font-weight: 700;
}

.mdl input {
  width: 24px;
  height: 24px;
}

.mdl label,
.mdl .col {
  font-size: 24px !important;
  margin-left: 7px !important;
  font-weight: 500 !important;
}

#dashboard-button,
.fancyBtn {
  background: #2DBDB6 !important;
  // height: 38px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  box-shadow: 0 3px 10px #2DBDB6 !important;
  border-color: transparent !important;
  color: white !important;
}


#import-button,
.fancyBtn {
  background: #fAC90E !important;
  border-color: #fAC90E !important;
  width: 50% !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  font-weight: bold;
  box-shadow: 0 3px 10px #FBC91D !important;
  color: black !important;
}

#search-button,
.fancyBtn {
  background: #FBC91D !important;
  height: 45px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  // box-shadow: 0 3px 10px #FBC91D !important;
  border-color: transparent !important;
  color: black !important;
  width: ''
}

#map-button {
  background: #2DBDB6 !important;
  height: 40px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  box-shadow: 0 3px 10px #2DBDB6 !important;
  border-color: transparent !important;
  color: white !important;
  width: '';
  cursor: none;
}

/* New dashboard */
.dash_wel {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 34px;
}

.dash_status_card {
  padding: 10px 16px 10px 36px;
  background: #35BDB4;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 22px;
  grid-gap: 100px;
  gap: 100px;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.dash_status_card img {
  object-fit: contain;
  max-width: 40px;
}

.dash_status_cnt {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  color: #fff;
  text-align: right;
  line-height: 1.2;
}

.dash_status_cnt span {
  font-size: 18px;
}

/* dashboard breadcrumb */
.dash_bredcrumb {
  display: flex;
  justify-content: space-between;
  background: #E9E9E9;
  margin-top: 32px;
  font-family: 'Montserrat', sans-serif;
}

.lft_ctnt {
  flex: 0 0 60%;
  padding: 6px 15px;
}

.icn_btn {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  flex: 0 0 400px;
}

.icn_btn img {
  max-width: 16px;
  margin-top: -3px;
}

.icn_btn div {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.pop_btn {
  font-size: 16px;
  padding: 3px 6px;
  font-weight: 800;
  background: $mysa-blue;
  cursor: pointer;
}

.rgt_ctnt {
  background: #5F5F5F;
  flex-grow: 1;
  padding: 6px 15px;
  max-width: 400px;
}

.rgt_ctnt .icn_btn {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 40px;
}

div[role="tooltip"] {
  z-index: 9999;
}

/* Dashboard stats */
.stat_crd {
  display: flex;
  background: $mysa-yellow;
  padding: 20px 20px 30px;
  border-radius: 20px;
  color: #fff;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
}

.stat_crd.stat_prg {
  padding-bottom: 20px;
}


.stat_crd_op {
  display: flex;
  background: $mysa-blue;
  padding: 20px 20px 30px;
  border-radius: 20px;
  color: #fff;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
}

.stat_crd_op.stat_prg {
  padding-bottom: 5px;
}

.col-md-3:nth-child(2) .stat_crd,
.App .dash_cnt .stat_crd_green {
  background-color: $mysa-green;
}

.col-md-3:nth-child(3) .stat_crd,
.App .dash_cnt .stat_crd_pink {
  background-color: $mysa-pink;
}

.col-md-3:nth-child(4) .stat_crd,
.App .dash_cnt .stat_crd_blue {
  background-color: $mysa-blue;
}

.App .dash_cnt .stat_crd_yellow {
  background-color: $mysa-yellow;
}

.App .dash_cnt .stat_crd_charcoal {
  background-color: $mysa-charcoal !important;
  justify-content: flex-start;
  padding: 30px 20px;
}

.App .dash_cnt .stat_crd_charcoal .stat_ctnt {
  width: 100%;
}

.App .dash_cnt .stat_crd_charcoal .booking_request_sec li {
  justify-content: space-between;
}

.App .dash_cnt .stat_crd_blue {
  background-color: $mysa-blue;
}

.inventory-sub-menu .brand_modal ul li.no-accommodation:hover {
  background: transparent;
  color: #000;
}

.gtr-3 {
  --bs-gutter-x: 2rem !important;
}

.stat_crd img {
  object-fit: contain;
  max-width: 72px;
}

.stat_ctnt {
  text-align: right;
}

.stat_ctnt .nmbr {
  font-size: 32px;
  line-height: 1.3em;
}

.stat_ctnt .nmbr_height {
  min-height: 55px;
  color: #fff;
}

.card_box_wrapper {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.card_box_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card_box_sec {
  max-width: 30%;
  width: 100%;
}

.tabsection-superadmin .card_box_sec {
  max-width: 25%;
  width: 100%;
}

.booking_request_sec li.mysa-pink-text {
  font-weight: 700;
}

.booking_request_sec li.mysa-pink-text span {
  color: #E80F8B;
}

.booking_request_sec li.mysa-green-text,
.mysa-text-bold {
  font-weight: 700;
}

.booking_request_sec li.mysa-green-text span {
  color: $mysa-green;
}

.stat_ctnt h6 {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0;
}

.stat_ctnt_list li {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.stat_ctnt_list li span {
  font-size: 20px;
  font-weight: 500;
  flex: 0 0 10px;
  text-align: center;
}


.stat_ctnt_op {
  text-align: right;
}

.stat_ctnt_op .nmbr {
  font-size: 42px;
  line-height: 1.3em;
}

.stat_ctnt_op h6 {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0;
}

.stat_ctnt_op_list li {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.stat_ctnt_op_list li span {
  font-size: 20px;
  font-weight: 500;
  flex: 0 0 10px;
  text-align: center;
}

.stat_crdClient {
  display: flex;
  background: $mysa-blue;
  padding: 20px 20px 30px;
  border-radius: 20px;
  color: #fff;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
}

.stat_crdClient.stat_prg {
  padding-bottom: 20px;
}

.col-sm-3:nth-child(2) .stat_crd {
  background-color: $mysa-green;
}

.col-md-3:nth-child(3) .stat_crd {
  background-color: $mysa-pink;
}

.col-md-3:nth-child(4) .stat_crd {
  background-color: $mysa-blue;
}

.gtr-3 {
  --bs-gutter-x: 2rem !important;
}

.stat_crdClient img {
  object-fit: contain;
  max-width: 72px;
}

.stat_ctntClient {
  text-align: left;
}

.stat_ctntClient .nmbr {
  font-size: 40px;
  font-weight: bold;
  // line-height: 1.3em;
}

.stat_ctntClient .text {
  font-size: 20px;
  font-weight: bold;
  // line-height: 1.3em;
}

.stat_ctntClient h6 {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0;
}

.col-sm-6:nth-child(2) .stat_crdClient {
  background-color: $mysa-yellow ;
}

.col-sm-6:nth-child(3) .stat_crdClient {
  background-color: $mysa-green;
}

.col-sm-6:nth-child(4) .stat_crdClient {
  background-color: $mysa-pink;
}

.col-sm-6:nth-child(5) .stat_crdClient {
  background-color: #FF0000;
}


.col-sm-6:nth-child(2) .stat_crd_op {
  background-color: $mysa-yellow ;
}

.col-sm-6:nth-child(3) .stat_crd_op {
  background-color: $mysa-pink;
}

.col-sm-6:nth-child(4) .stat_crd_op {
  background-color: $mysa-yellow;
}



/* Table Accordion */

.tbl_accr_itm {
  background: #52d1c2;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  margin: 10px 0 40px;
}

.tbl_hdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #52d1c2;
  border-radius: 22px;
  padding: 12px 60px;
}

.tbl_hdr h5 {
  font-size: 24px;
}

.tbl_hdr button {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.tbl_hdr button img {
  max-width: 12px;
  object-fit: contain;
  filter: invert(1);
}

.ntf_list {
  padding: 0px 60px 80px 18px;
  background: #fff;
  border-radius: 0 0 22px 22px;
}

.ntf_list_item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 40px;
  position: relative;
  border-bottom: 1px solid #999999;
}

.ntf_list_item:after {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $mysa-green;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.table thead {
  background: #C4C4C4;
  position: relative;
}

.table thead:after,
.table thead:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 100%;
  background: #c4c4c4;
  top: 0px;
  left: -18px;
}

.table thead:after {
  left: auto;
  right: -60px;
  width: 65px;
}

.table thead th {
  color: #fff;
}

.table thead th:first-child {
  width: 36px;
}

.tbl_accr .acrd_tbl {
  padding: 0px 60px 80px 18px;
  background: #fff;
  border-radius: 0 0 22px 22px;
}

.table thead th:not(:first-child) {
  position: relative;
}

.table thead th:not(:first-child):after {
  content: "";
  width: 1px;
  height: 22px;
  background-color: #fff;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}


/* dashboard Charts */
.chart_bx {
  background-color: #E9E9E9;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
}

.chart_hdg {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  border-radius: 22px 22px 0 0;
  padding: 10px;
}

.chart_body {
  padding: 15px;
  min-height: 395px;
}

.chartInfo li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #999999;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  padding-left: 40px;
  position: relative;
}

.dot {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

/* New tabbing Design */
/* Property Design */
.new_tab_wpr {
  margin-top: 65px !important;
}

ul.ant-menu.new_tab li {
  font-family: 'Montserrat';
  font-weight: 600;
  line-height: 13px !important;
  padding: 14px 18px !important;
  text-align: center;
  border-radius: 22px 22px 0 0;
  color: #fff !important;
  cursor: pointer;
  min-width: 180px;
  background-color: #f8c735 !important;
  margin-bottom: 1px !important;
  // transition: all 0.3s ease-in-out 0.1s;
}

ul.ant-menu.new_tab li:nth-child(2n) {
  background-color: $mysa-green !important;
}

ul.ant-menu.new_tab li:nth-child(3n) {
  background-color: $mysa-pink !important;
}

ul.ant-menu.new_tab li:nth-child(4n) {
  background-color: $mysa-blue !important;
}


.operator_menu ul.ant-menu.new_tab li:nth-child(2n) {
  background-color: $mysa-pink !important;
}

.operator_menu ul.ant-menu.new_tab li:nth-child(3n) {
  background-color: $mysa-green !important;
}

ul.ant-menu.new_tab li.ant-menu-item-selected {
  padding: 14px 20px 24px !important;
}

ul.ant-menu.new_tab li:after {
  display: none;
}

.new_tab {
  border-bottom: 0;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 50px;
  background-color: #0000;
}

.new_tab_ctnt {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  padding: 40px 90px 40px 35px;
}

.new_tab_ctnt2 {
  padding: 55px !important;
}

.new_tab_ctnt_inr ul {
  list-style: disc;
}

.new_tab_ctnt_inr h2 {
  font-size: 24px;
}

.new_drpdwn {
  min-width: 320px;
}

.new_drpdwn>span+div {
  background: #fff;
  box-shadow: 0px 3px 10px #fac90e;
  border-radius: 40px;
  padding-left: 4px;
  height: 47px;
  cursor: pointer;
  border-color: #0000;
}

.new_drpdwn>span+div+div {
  z-index: 9999;
}

.new_drpdwn>span+div>div:nth-child(2) span {
  width: 2px;
  background-color: #000;
}


.new_drpdwn2 {
  min-width: 320px;
}

.new_drpdwn2>span+div {
  background: #fff;
  box-shadow: 0px 3px 10px #2DBDB6;
  border-radius: 40px;
  padding-left: 4px;
  height: 47px;
  cursor: pointer;
  border-color: #0000;
}

.new_drpdwn2>span+div+div {
  z-index: 9999;
}

.new_drpdwn2>span+div>div:nth-child(2) span {
  width: 2px;
  background-color: #000;
}


.new_progDrpdDwn {
  min-width: 320px;
}

.new_progDrpdDwn>span+div {
  background: #fff;
  box-shadow: 0px 3px 10px #22AEE2;
  border-radius: 40px;
  padding-left: 4px;
  height: 47px;
  cursor: pointer;
  border-color: #0000;
}

.new_progDrpdDwn>span+div+div {
  z-index: 9999;
}

.new_progDrpdDwn>span+div>div:nth-child(2) span {
  width: 2px;
  background-color: #000;
}



.new_accDrpdDwn {
  min-width: 320px;
}

.new_accDrpdDwn>span+div {
  background: #fff;
  box-shadow: 0px 3px 10px #0000;
  border-radius: 40px;
  padding-left: 4px;
  height: 47px;
  cursor: pointer;
  border-color: #0000;
}

.new_accDrpdDwn>span+div+div {
  z-index: 9999;
}

.new_accDrpdDwn>span+div>div:nth-child(2) span {
  width: 2px;
  background-color: #000;
}

.new_tab_ctnt .rdt_Table .rdt_TableHeadRow,
.new_tab_ctnt .tble_rw {
  background: #fff;
  padding: 10px 33px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.22) !important;
  border-radius: 11px;
  min-height: 48px;
}

.tble_rw.row>div:not(:last-child) {
  border-right: 2px solid #000;
}

.tble_rw .ant-typography {
  font-size: 16px;
  color: #000;
}

.new_tab_ctnt .rdt_Table .rdt_TableCol {
  font-size: 16px;
}

.new_tab_ctnt .rdt_TableBody .rdt_TableRow {
  min-height: 64px;
}

.new_tab_ctnt .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: #E9E9E9;
}

.new_tab_ctnt .rdt_TableBody .btn {
  height: 35px !important;
}

.new_tab_ctnt .rdt_Pagination {
  min-height: 30px;
}

.icn_txt {
  font-size: 20px;
  font-weight: 700;
}

.grey_bx {
  border-radius: 44px;
  padding: 30px;
  background-color: #0000000d;
}

.strok_bx {
  padding: 30px;
  height: 100%;
  border: 4px solid #C4C4C4;
  border-radius: 44px;
}

.grey_bx p,
.strok_bx p {
  font-weight: 700;
  margin-bottom: 6px;
}

.aprt_bx {
  border-radius: 44px;
  padding: 40px 60px;
  background-color: #0000000d;
}

.rdt_TableCol_Sortable {
  gap: 7px;
}

.rdt_TableCol_Sortable img {
  margin-top: 2px;
}

.tbl-hdr.arw {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 700;
  border-left: 2px solid #000;
}

.prt_tbl p {
  margin-bottom: 0;
  text-align: center;
}

.prt_grey_bx {
  padding: 60px;
}

.icn_list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.icn_list img {
  border-radius: 50%;
}

.qr_btn>div>div:nth-child(2) {
  padding: 0 20px;
}

.qr_btn>div>div:nth-child(2) span {
  margin: 24px 10px 24px 0;
  width: 2px;
  background-color: #000;
}

.underline_tabs {
  border: 0 !important;
}

.underline_tabs li {
  padding: 0 !important;
}

.underline_tabs li button {
  border: 0 !important;
  border-bottom: 5px solid #0000 !important;
  padding: 12px 0;
  margin-right: 25px;
  font-weight: 700;
  color: #000;
}

.underline_tabs li button.active {
  border-bottom-color: $mysa-yellow !important;
  // color: #000 !important;
}

.underline_tabs li button:hover {
  color: #000 !important;
}

/* tab pane */
.tab-pane .breadcrumb {
  margin-top: 5px;
}

.tab-pane .flex-bx {
  align-items: center;
  margin-top: 49px;
}

.google-map {
  min-height: 300px;
}

// Client Proposal managment
.about_stats {
  padding-right: 80px;
}

.about_stats .stat_crd {
  background: $mysa-blue;
}

.about_stats .col-md-4:nth-child(2n+2) .stat_crd {
  background-color: $mysa-yellow;
}

.about_stats .col-md-4:nth-child(2n+3) .stat_crd {
  background-color: $mysa-pink;
}

.about_box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.about_txt {
  // flex-grow: 1;
  // height: 100%;
}

.cntct_box_hdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social_links {
  display: flex;
  list-style: none !important;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.social_links li {
  transition: all 0.3s ease-in-out 0.1s;
}

.social_links li:hover {
  transform: scale(0.90);
}

.cntct_box_list {
  padding: 0;
  margin: 0;
  list-style: none !important;
}

.cntct_box_list li,
.cntct_box_list li a {
  display: flex;
  grid-gap: 14px;
  gap: 14px;
  align-items: center;
  font-size: 18px;
}

.cntct_box_list li {
  margin-bottom: 20px;
}

.cntct_box_list li img {
  flex: 0 0 25px;
  text-align: center;
  max-height: 34px;
}

list-group {
  /* --bs-list-group-color: #212529; */
  /* --bs-list-group-bg: #fff; */
  /* --bs-list-group-border-color: rgba(0, 0, 0, 0.125); */
  /* --bs-list-group-border-width: 1px; */
  /* --bs-list-group-border-radius: 0.375rem; */
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.css-lzr3bi-LoadingOverlayWrapperBase {
  position: fixed !important;
}

div.pac-container {
  z-index: 1056 !important;
}

// 02-04-2024

.new_tab_ctnt .listing-group-section ul li .positive-ans {
  flex-wrap: wrap;
  align-items: flex-start !important;
  flex-direction: column !important;
  border-radius: 15px;
  height: auto !important;
  margin: 0 0 10px 0;
}

.new_tab_ctnt .listing-group-section ul li .list_img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.radio_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  left: 0;
  height: 100%;
}

.new_tab_ctnt .listing-group-section ul li span .radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.checkcontainer input:checked~.radiobtn {
  background-color: #2196F3;
}

.checkcontainer .radiobtn:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  display: none;
}


.new_tab_ctnt .listing-group-section ul li span.radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 0;
  min-width: auto;
}

.checkcontainer input:checked~span.radiobtn {
  background-color: #fac90e;
}

.checkcontainer span.checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
  display: none;
}

.checkcontainer input:checked~.radiobtn:after {
  display: block;
}




.new_tab_ctnt .listing-group-section ul li span.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  min-width: auto;
  padding: 0;
  display: inline-block;
  border-radius: 5px;
}

.checkcontainer input:checked~.checkmark {
  background-color: #2196F3;
}

.checkcontainer input:checked~span.checkmark {
  background-color: #fac90e !important;
}

.answer_tag {
  display: flex;
  gap: 15px;
}

.new_tab_ctnt .listing-group-section ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.new_tab_ctnt .listing-group-section ul li .answer {
  background: transparent;
  display: flex !important;
  height: auto !important;
  flex-direction: column;
  font-size: 16px;
  text-align: left;
  line-height: 26px;
  font-weight: 600;
  border: 1px solid #eee;
  margin: 0 0 15px 0;
  padding: 10px 20px !important;
  position: relative;
  box-shadow: 4px 8px 16px #0000000f;
  border-left: 1px solid #eee;
  border-radius: 15px !important;
  align-items: flex-start !important;
  width: 100%;
}

.new_tab_ctnt .listing-group-section ul li p {
  font-size: 20px;
  line-height: 28px;
  margin: 0;
}

.new_tab_ctnt .listing-group-section ul li span {
  display: inline-block;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  font-size: 16px;
  min-width: 50px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.new_tab_ctnt2 {
  border: 1px solid #eee;
  box-shadow: none;
}

.checkcontainer input:checked~.checkmark:after {
  display: block;
}

.new_tab_ctnt .listing-group-section ul li div {
  width: 100%;
}

.checkcontainer input:checked~.radiobtn {
  background-color: #fac90e !important;
}

.dropdown-property .new_drpdwn>span+div {
  box-shadow: none;
  border: 1px solid #eee;
  padding: 5px 16px;
  box-shadow: 0px 3px 10px #fac90e;
}

.dropdown-property .new_drpdwn>span+div>div:nth-child(2) span {
  background-color: #eee;
}

.listing-group-section .list-group-item {
  background-color: #fff;
  border-radius: 20px !important;
}

.listing-group-section .list-group-item.list-group-item-dark {
  padding: 0 !important;

}

.dropdown-property .new_drpdwn {
  min-width: 100%;
}

.checkbox_wrapper h6 {
  margin: 0 0 20px 0;
  text-transform: capitalize;
  font-weight: 600;
}

.custom-slick-slider .custom_img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  border: 0;
}

.custom-slick-slider h6 {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  margin: 0;
  min-height: 40px;
}

.modal-popup .modal-body iframe {
  height: 700px;
}

.custom-slick-slider .slick-slide {
  padding: 0 10px;
}

.custom-slick-slider .custom_item {
  border: 1px solid #ddd;
  border-radius: 40px;
  overflow: hidden;
}

.custom-slick-slider .slick-prev:before,
.custom-slick-slider .slick-next:before {
  color: #fAC90E;
  opacity: 1 !important;
  font-size: 24px;
}

.modal-full-img {
  width: 100%;
  object-fit: cover;
  height: 600px;
  border-radius: 10px;
}

.modal .modal-content {
  border-radius: 15px !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.no-records-found {
  text-align: center;
  padding: 100px 0;
  background: #f2f2f2;
  border-radius: 20px;
}

.magenta_tab .dropdown-property .new_drpdwn>span+div {
  box-shadow: 0px 3px 10px #E80F8B;
}

.new_tab_ctnt .listing-group-section ul li .positive-ans {
  border-left: 4px solid green;
}

.new_tab_ctnt .listing-group-section ul li .negative-ans {
  border-left: 4px solid orange;
}

.new_tab_ctnt .css-1dimb5e-singleValue {
  font-weight: 600;
}


.booking-header {
  width: 1580px !important;
  height: 64px !important;
  gap: 0px !important;
  border-radius: 25px 25px 25px 25px !important;
  opacity: 0px !important;
  background: #35BDB4 !important;
}

.booking-header2 {
  height: 64px !important;
  gap: 0px !important;
  border-radius: 25px 25px 25px 25px !important;
  opacity: 0px !important;
  background: #35BDB4 !important;

}

.modal-popup .modal-body video {
  height: 550px;
}

.custom-slider-wrapper {
  padding-top: 30px;
  bottom: 0;
  width: 100%;
  position: sticky;
  background: #fff;
}

.programme-card {
  width: 240px;
  height: 185px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  border: 2px solid #0d6efd;


}

.programme-card2 {
  width: 240px;
  height: 270px;
  // margin-left: 10px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  // top: 570px;
  // left: 90px;
  border: 2px solid #0d6efd;
  // position: fixed;

}



.programme-card3 {
  width: 240px;
  height: 300px;
  // margin-left: 10px;
  gap: 0px;
  border-radius: 44px 44px 44px 44px;
  opacity: 0px;
  // top: 570px;
  // left: 90px;
  border: 2px solid #0d6efd;
  // position: fixed;

}

.booking_request_sec {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  text-align: left;
}

.card_box_sec a img {
  padding-right: 15px;
}

.card_box_sec a {
  display: flex;
  justify-content: center;
  padding: 30px;
  height: 250px;
  align-items: center;
  flex-direction: column;
}

.card_box_sec a h6 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin: 0 0 15px 0;
  height: auto;
  text-align: center;
  line-height: normal;
}

.booking_request_sec li {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  margin: 0;
  display: flex;
  align-items: center;
}

.booking_request_sec li span {
  display: inline-block;
  margin-right: 15px;
  font-size: 40px;
}

.brand_overview .card_box_sec a {
  height: 407px;
  align-items: flex-start;
  justify-content: flex-start;
}

.brand_overview .booking_request_sec {
  min-height: 180px;
}

.brand_overview .card_box_sec a h6 {
  font-size: 44px;
  line-height: 62px;
  text-align: left;
}

.brand_overview .booking_request_sec li {
  font-size: 32px;
  line-height: 42px;
}

.brand_overview .booking_request_sec li span {
  font-size: 64px;
  line-height: 82px;
  width: 100px;
  text-align: right;
}

.brand_overview .booking_request_sec li span.single-number {
  width: auto;
  text-align: left;
}

/* Super-admin dashboard bredcrumb start */

.dashboard_bredcrumb {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.dashboard_bredcrumb li a {
  display: flex;
  gap: 10px;
  border-bottom: 3px solid transparent;
}

.dashboard_bredcrumb li.active a {
  font-weight: 700;
  border-color: $mysa-pink;
}

.tabcontent {
  padding: 0;
  margin: 0;
}

/* Super-admin dashboard bredcrumb End */


/* css */

.superadmin-table .rdt_Table .rdt_TableHeadRow {
  background: #c4c4c4;
  box-shadow: none !important;
  border-radius: 0;
  padding: 10px 0px;
  min-height: 42px;
  margin: 0;
}

.superadmin-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  background: #c4c4c4;
  color: #fff;
  border-right: 2px solid #fff;
  padding-left: 10px;
}

.superadmin-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:first-child {
  padding-left: 15px;
}

.superadmin-table .acrd_tbl,
.superadmin-table .rdt_Table {
  padding: 0 0 20px 0;
}

.copy-icons {
  cursor: pointer;
}

.superadmin-table .row-item .rdt_TableCell {
  background: #fff;
}

.more-data-section {
  display: flex;
  justify-content: flex-end;
  padding: 0px 60px 0 0;
}

.more_button {
  font-size: 20px !important;
  color: #000 !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer !important;
}

.superadmin-table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:last-child {
  border-right: 0;
}

.outstanding-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}

.superadmin-table .rdt_TableCell div,
.superadmin-table .rdt_TableCell {
  font-size: 18px !important;
  background-color: #fff;
}

.resend_button {
  border: 0;
  box-shadow: none !important;
  background: transparent !important;
  color: #e7008c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.superadmin-table .row-item {
  background: transparent;
  border-bottom-color: #999999 !important;
}

.superadmin-table .row-item {
  border-bottom-color: #999999 !important;
}

.superadmin-table .rdt_TableCol_Sortable {
  font-size: 20px;
}

/* css */


/* modal css start */

.brand_modal .brand_header {
  height: 50px;
  border-radius: 22px 22px 0 0 !important;
  position: relative;
  display: flex;
  align-items: center;
}

.brand_modal .brand_header h3 {
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.brand_modal .brand_header .btn-close {
  font-size: 30px;
  opacity: 1;
  position: absolute;
  right: 20px;
  font-weight: 800;
}

.superadmin-table {
  position: relative;
}

.brand_modal .modal-dialog {
  border-radius: 22px !important;
  overflow: hidden;
  box-shadow: #00000040;
  max-width: 600px;
}

.brand_content {
  border-radius: 22px;
}

.brand-dialog {
  overflow: hidden;
  box-shadow: 4px 4px 4px #00000040;
  width: 600px;
  margin: 0 auto;
  border-radius: 22px;
}

.cancel-icon {
  width: 30px;
  position: absolute;
  right: 25px;
  top: 10px;
  cursor: pointer;
}

.brand-modal {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
}

.superadmin-table.show .brand-modal {
  opacity: 1;
  display: block;
  width: 100%;
}

.superadmin-table.show .tbl_accr_itm {
  position: relative;
}

.superadmin-table.show .tbl_accr_itm:before {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  opacity: 0.7;
}

.superadmin-table.superadmin-height {
  padding-bottom: 230px;
}

.brand_modal ul {
  padding: 0 0 40px 0;
  margin: 0;
  background: #E9E9E9;
  overflow: auto;
  max-height: 460px;
}



.brand_modal ul li {
  list-style-type: none;
  font-size: 26px;
  line-height: 36px;
  border-bottom: 1px solid #999999;
  padding: 13px 65px;
  position: relative;
  padding-right: 112px;
}

.brand_modal .brand_body {
  padding: 0;
}

.brand_modal ul li:last-child {
  border-bottom: 0;
}

.brand_modal .point-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  right: 85px;
  top: 21px;
}


.brand_header-backdrop {
  background: #fff;
  opacity: 0.7 !important;
}

.incomplete-table .brand-modal {
  top: 105px;
  left: 60px;
  right: auto;
  width: auto !important;
}

.incomplete-table .brand-dialog {
  margin: 0;
}

.incomplete-table .brand_modal ul {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 20px;
}

.inventory-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px !important;
  font-size: 24px !important;
  font-weight: 700;
  gap: 20px;
}

.inventory-btn img {
  width: 40px;
  object-fit: contain;
  margin-top: 5px;
}

.incomplete-table .brand-modal {
  padding-bottom: 80px;
}

.inventory-modal-hide {
  display: none;
}

.inventory-modal-show {
  display: block;
}

.incomplete-table .inventory-modal-show .brand-modal {
  left: 305px;
}

.incomplete-table .inventory-sub-menu-list .brand-modal {
  left: 924px;
}

.white_layer {
  background: #ffffffb3;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
}

.inventory-modal-show .brand-modal {
  z-index: 101;
}

.inventory-sub-menu .brand_modal ul li {
  text-transform: capitalize;
  transition: all 0.3s linear 0s;
}

.inventory-sub-menu .brand_modal ul li:hover {
  background: #e60f894d;
  color: #4c0129;
  transition: all 0.3s linear 0s;
}

/* 01-10-2024 css end */

.slider_approve_btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 0;
  margin-bottom: 0 !important;
  position: relative;
  width: 130px !important;
  justify-content: end;
}

.slider_approve_btn:after {
  content: "";
  position: absolute;
  left: 24px;
  top: 5px;
  height: 23px;
  width: 23px;
  background: url(../images/icons/svg/outside/search.svg) no-repeat center left;
}

.hotel_fancy_slider .relative:before {
  content: "";
  position: absolute;
  width: 98%;
  height: 95%;
  top: 6px;
  left: 0;
  background: #00000042;
}

.yellow_shadow_btn {
  background: transparent !important;
  height: 42px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 10px #FAC90E !important;
  border-color: transparent !important;
  color: #000 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

/* modal css end */

.superadmin-table .tbl_hdr {
  padding: 12px 15px 12px 15px;
}



.magneta_tab ul.ant-menu.new_tab li:nth-child(2n) {
  background-color: $mysa-green !important;
}

.magneta_tab ul.ant-menu.new_tab li:nth-child(3n) {
  background-color: $mysa-pink !important;
}

.terms-band {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.terms-band-link {
  margin: 10px 0 0 0;
  font-weight: bold;
  text-decoration: underline;
}

.terms-band-link:hover {
  color: #000;
  text-decoration: underline;
}

.terms-band-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.terms-band-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.customClearDataBtn {
  padding: 10px 30px !important;
  box-shadow: 0 6px 12px #2dbdb6 !important;
  color: #000 !important;
  background: #f5f5f5 !important;
  display: flex !important;
  align-items: center;
  font-size: 16px !important;
}

.customDeleteBtn {
  padding: 10px 30px !important;
  box-shadow: 0 6px 12px rgba(250, 201, 14, 1) !important;
  color: #000 !important;
  background: #f5f5f5 !important;
  display: flex !important;
  align-items: center;
  border-radius: 22px !important;
}

.customSaveBtn {
  color: #000000 !important;
  border-radius: 44px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  box-shadow: 0 3px 10px #2dbdb6 !important;
  height: 35px !important;
}

#customEditBtn {
  background: transparent !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 10px #fac90e !important;
  border-color: transparent !important;
  color: #000 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  height: 35px !important;
}

.formSpace .mysa-form-control {
  height: 35px;
  border-radius: 0;
  padding-left: 20px;
  padding-top: 4px;
  color: #212529;
}

.mysa-select.ant-select .ant-select-selector {
  height: 35px;
  border-radius: 0;
  padding-left: 20px;
  padding-top: 0;
}

.mysa-select span.ant-select-selection-item {
  padding-left: 2px !important;
  line-height: 30px !important;
  color: #212529;
  font-size: 16px;
  text-transform: capitalize;
}

.mysa-multiselect span.ant-select-selection-item {
  padding-left: 2px !important;
  color: #212529;
  font-size: 16px;
  text-transform: capitalize;
}

.mysa-multiselect.ant-select .ant-select-selector {
  height: auto;
  border-radius: 0;
  padding-left: 20px;
  padding-top: 0;
  min-height: 35px;
  overflow: auto;
  max-height: 80px;
}

.mysa-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px !important;
}

.mysa-select .ant-select-arrow .anticon {
  right: 10px;
  bottom: 0;
}

.mysa-create-form label {
  padding-left: 50px;
  margin: 7px 0 0 0;
}

.mysa-yellow-button {
  color: #000 !important;
  background-color: #fac90e !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  margin: 0;
  font-size: 16px !important;
  padding: 5px 30px !important;
  height: 42px !important;
  border-radius: 40px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

}

.mysa-create-form .formSpace {
  margin-top: 15px !important;
}

.mysa-create-form .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  padding-left: 12px !important;
}

.mysa-create-form .formSpace.d-flex {
  display: flex;
  align-items: center;

}

.modal-open .ant-select-dropdown {
  z-index: 10507;
}

.customSummaryColumn {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
  min-height: 150px !important;
  max-height: 150px !important;
  overflow-y: auto;
  background-color: #fff
}

.inputFuild .requiredMark {
  position: absolute;
  top: -5px;
  left: 0;
  font-size: 18px;
}

.inputFuild {
  position: relative;
  padding-left: 15px;
}

.inputFuild .ant-select-selection-search-input {
  padding-left: 10px !important;
}

.inputFuild input.form-control {
  height: 45px;
  border-radius: 0;
  border-color: #d9d9d9;
}

.inputFuild input::placeholder {
  color: #bfbfbf;
}

.inputFuild input.form-control:focus {
  box-shadow: none;
}

.inputFuild input:-internal-autofill-selected {
  border-color: #40a9ff;
}

.placeholder_bold div {
  font-weight: 600;
  color: #000 !important;
}

.tab-content #routing-button-blue-brand {
  height: 42px !important;
}

#add-button {
  box-shadow: 0 3px 10px #fac90e !important;
  color: #000 !important;
  font-weight: 600 !important;
  margin: 0;
  font-size: 16px !important;
  padding: 5px 30px !important;
  height: 42px !important;
  border-radius: 40px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

#edit-row-button,
#cancel-button,
#close-button {
  color: #000 !important;
  border-radius: 44px !important;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 3px 10px #fac90e !important;
}

#edit-button {
  background: transparent !important;
  height: 42px !important;
  border-radius: 20px !important;
  box-shadow: 0 3px 10px #2dbdb6 !important;
  border-color: transparent !important;
  color: #000 !important;
  padding-left: 30px;
  padding-right: 30px;
}

.contractedProperty .extra-row {
  display: flex;
}

.contractedProperty .extra-row-cell {
  color: #fff;
  font-size: 19px;
  text-align: center;
}

.contractedProperty .extra-row-cell:nth-child(1) {
  width: 52.6%;
  padding-left: 245px;
}

.contractedProperty .extra-row-cell:nth-child(2) {
  width: 47.4%;
}

.contractedProperty .rdt_TableHeader {
  background: #324E5D;
  min-height: auto;
  padding: 0;
}

.reports_table .rdt_Table {
  padding: 0;
}

.contractedProperty .reports_table .rdt_Table .rdt_TableHeadRow {
  min-height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.reportTableButton {
  border-radius: 0;
  box-shadow: none;
}

.reportTableButton span+div {
  border-radius: 10px;
  border: 6px solid #324E5D;
  border-radius: 10px;
}

.reportTableButton span+div div {
  border: 0;
  border-radius: 0;
}

.reportTableButton span+div div span {
  background-color: #fff;
}

.reportTableButton span+div svg {
  color: #324E5D;
}

.reportTableButton span+div:hover {
  border-color: #324E5D;
}

.reports_table .rdt_TableRow {
  min-height: 40px;
}

.reports_table .rdt_TableRow:nth-child(odd) {
  background: #fff;
}

.reports_table .rdt_TableRow:nth-child(even) {
  background: #22AEE233;
}


/* width */
.reports_table::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Track */
.reports_table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.reports_table::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.reports_table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.reports_table {
  max-height: 850px !important;
}

.chain_filter .reports_table {
  max-height: 750px !important;
}

.reports_table .rdt_TableHead {
  top: 0px;
  z-index: 2;
}

.location_reports .tab-content nav[aria-label="breadcrumb"] {
  width: 100%;
}

.fix_header {
  position: fixed;
  background: #fff;
  max-width: 80%;
  top: 81px;
  padding: 35px 0 0;
  z-index: 999;
  width: 100%;
}

.location_reports .brnd_tbl {
  position: relative;
}

.location_reports .rdt_Pagination {
  position: absolute;
  top: -135px;
  right: 0;
  z-index: 999;
  background: transparent;
  width: 100%;
  max-width: 500px;
}

.chain_filter.location_reports .rdt_Pagination {
  top: -110px;
}

.reports_table .rdt_TableCol .rdt_TableCol:nth-child(1),
.reports_table .rdt_TableCol .rdt_TableCol:nth-child(2),
.reports_table .rdt_TableRow .rdt_TableCell:nth-child(1),
.reports_table .rdt_TableRow .rdt_TableCell:nth-child(2) {
  position: sticky;
  z-index: 1;
  // background-color: #fff;
}

.reports_table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  position: sticky;
  z-index: 1;
  // background-color: #324E5D;
  left: 0;
}

.reports_table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  position: sticky;
  z-index: 1;
  // background-color: #324E5D;
  left: 120px;
}

.reports_table .rdt_TableCol .rdt_TableCol:nth-child(1) {
  left: 0;
}

.reports_table .rdt_TableCol .rdt_TableCol:nth-child(2) {
  left: 120px;
}

.reports_table .rdt_TableRow .rdt_TableCell:nth-child(1) {
  left: 0;
}


.reports_table .rdt_TableRow .rdt_TableCell:nth-child(2) {
  left: 120px;
}

.reports_table .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(1),
.reports_table .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(2) {
  min-width: 120px;
}

.reports_table_location_overview .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
.reports_table_location_overview .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(5),
.reports_table_location_overview.reports_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(11) {
  min-width: 117px !important;
}

.reports_table_location_overview .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(3),
.reports_table_location_overview .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(5),
.reports_table_location_overview.reports_table .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(11) {
  min-width: 117px !important;
}

.reports_table_location_overview.reports_table .rdt_Table .rdt_TableHeadRow .rdt_TableCol:nth-child(6),
.reports_table_location_overview.reports_table .rdt_Table .rdt_TableRow .rdt_TableCell:nth-child(6) {
  min-width: 120px !important;
}

.chain-sec {
  display: inline-flex;
  background-color: #5F5F5F;
  padding: 10px 30px !important;
  border-radius: 10px;
  margin: 0 0 30px 0;
  gap: 10px;
  width: auto !important;
  min-width: 100%;
  justify-content: space-between;
}

.chain-list ul {
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
}

.chain-title {
  margin: 0 0 20px 0;
  color: #22AEE2;
  font-weight: 700;
}

.chain_hover:hover {
  color: #19AFE6;
  text-decoration: underline;
}

.chain-list ul li {
  color: #fff;
  font-size: 20px;
  min-height: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

@media (max-width: 1600px) {

  .brand_card_sec .stat_crd {
    min-height: 145px;
  }

  .brand_card_sec .stat_ctnt h6 {
    font-size: 20px;
  }

  .incomplete-table .inventory-modal-show .brand-modal {
    left: 100px;
  }

  .incomplete-table .inventory-sub-menu-list .brand-modal {
    left: 724px;
  }
}

@media (max-width: 1400px) {
  .booking_request_sec li {
    font-size: 20px;
  }

  .brand-dialog {
    width: 500px;
  }

  .incomplete-table .inventory-sub-menu-list .brand-modal {
    left: 624px;
  }
}

@media (max-width: 1300px) {
  .card_box_sec {
    max-width: 46%;
  }

  .brand-dialog {
    width: 450px;
  }

  .incomplete-table .inventory-sub-menu-list .brand-modal {
    left: 570px;
  }

  .brand_modal ul li {
    padding: 13px 40px;
  }
}

@media (max-width: 1100px) {
  .incomplete-table .inventory-sub-menu-list .brand-modal {
    left: 95px;
    top: 340px;
  }
}

@media (max-width: 992px) {
  .card_box_sec {
    max-width: 100%;
  }
}